@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
.Header {
	.navbar-brand {
		font-weight: bold !important;
	}
	.header-img {
		margin-top: -20px !important;
		margin-bottom: -20px !important;
	}
	.navbar {
		background-color: #002c69 !important;
	}
	.navbar-toggler {
		color: white !important;
		background-color: white !important;
	}
	.navbar-toggler-icon {
		color: white !important;
		background-color: white !important;
	}
	.contactus-btn {
		background-color: #ff6600;
		color: #fefeff;
		display: inline-flex;
		text-decoration: none !important;
		padding: 12px 30px;
		align-items: flex-start;
		gap: 8px;
		border-radius: 32px;
		border: none;

		font-weight: 600;
		font-size: 18px;
		line-height: normal;
		font-family: 'Manrope', sans-serif;
	}

	.menuitem {
		color: #fefeff !important;
		font-family: 'Manrope', sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}

.Footer {
	background-color: #002c69 !important;
	.menuitem {
		color: rgb(75 85 99);
		font-family: 'Manrope', sans-serif;

		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.footeritem {
		color: #add8e6;
		font-family: 'Manrope', sans-serif;
		list-style: none;
	}
	.header-img {
		margin-top: -45px !important;
		margin-bottom: -50px !important;
	}
	.desp {
		color: rgb(75 85 99);
		font-family: 'Manrope', sans-serif;
	}
	.footer-text {
		color: black;
		font-weight: 500;
	}
	.email {
		text-decoration: none;
		color: #1184e8;
	}

	.heading {
		color: #f3f4f6;
		font-family: 'Manrope', sans-serif;
	}
	.para {
		color: #add8e6;
	}
	.aboutus {
		color: #f3f4f6;
		font-family: 'Manrope', sans-serif;
		margin-top: 7.5rem;
		margin-left: 2rem;
	}
	.address {
		color: #f3f4f6;
		font-family: 'Manrope', sans-serif;
		margin-top: 7.5rem;
		// margin-left: 2rem;
	}
	@media (max-width: 425px) {
		.aboutus {
			color: #f3f4f6;
			font-family: 'Manrope', sans-serif;
			margin-top: 0rem !important;
			margin-left: 0rem;
		}
		.address {
			color: #f3f4f6;
			font-family: 'Manrope', sans-serif;
			margin-top: 0rem !important;
			// margin-left: 2rem;
		}
		.footeritem {
			margin-left: -2rem;
		}
	}
}

.Faqs {
	// background-color: #161b25;
	.faqs-btn {
		background-color: whitesmoke;
	}
	.heading {
		color: #002c69 !important;
		font-family: 'Manrope', sans-serif;
	}
}

.Heropage {
	// background-color: #161b25;
	// height: 80vh;
	.hero-img {
		border-radius: 20px;
	}
	.heading-padding {
		padding-top: 3.5rem;
		padding-bottom: 3rem;
	}

	@media (max-width: 425px) {
		.heading-padding {
			padding-top: 0rem;
			padding-bottom: 0rem;
		}
	}
	.heading {
		color: #002c69 !important;
	}
	.contactus-btn {
		background-color: #ff6600;
		color: #fefeff;
		display: inline-flex;
		text-decoration: none !important;
		padding: 12px 30px;
		align-items: flex-start;
		gap: 8px;
		border-radius: 32px;
		border: none;

		font-weight: 600;
		font-size: 18px;
		line-height: normal;
		font-family: 'Manrope', sans-serif;
	}
	.para {
		font-size: 18px;
		color: black;
	}
}

.Empowering {
	background-color: #002c69;
	// height: 80vh;
	.heading {
		color: #f3f4f6;
		font-family: 'Manrope', sans-serif;
	}
	.para {
		color: #add8e6;
		font-family: 'Manrope', sans-serif;
	}
}

.BottomFooter {
	// .heading {
	// 	color: #002c69 !important;
	// }
	#myBtn {
		width: 70px;
		position: fixed;
		bottom: 15px;
		right: 10px;
	}
}

.Taxidispatch {
	.taxi-para {
		color: #002c69;
		font-size: 18px;
		font-family: 'Manrope', sans-serif;
		font-weight: 500;
	}
	.taxi-heading {
		font-family: 'Manrope', sans-serif;
		color: #002c69;
		font-weight: bold;
	}
	.hero-img {
		border-radius: 20px;
	}
}

.Customersupport {
	.taxi-para {
		color: #002c69;
		font-size: 18px;
		font-family: 'Manrope', sans-serif;
		font-weight: 500;
	}
	.taxi-heading {
		font-family: 'Manrope', sans-serif;
		color: #002c69;
		font-weight: bold;
	}
}

.customersatisfaction {
	background-color: #002c69;
	.heading {
		color: #f3f4f6;
		font-family: 'Manrope', sans-serif;
	}
	.para {
		color: #add8e6;
		font-family: 'Manrope', sans-serif;
	}
}

.Aboutus {
	background-color: whitesmoke;
	.taxi-para {
		color: #002c69;
		font-size: 18px;
		font-family: 'Manrope', sans-serif;
		font-weight: 500;
	}
	.taxi-heading {
		font-family: 'Manrope', sans-serif;
		color: #002c69;
		font-weight: bold;
	}
	.hero-img {
		border-radius: 20px;
	}
}
